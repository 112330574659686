<template>
    <div>Parent</div>
</template>

<script>
export default {
    created() {
        
    }
}
</script>