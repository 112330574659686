var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card mx-auto w-max-5" }, [
    _c("div", { staticClass: "card-header" }, [_vm._v("Přihlášení")]),
    _vm._v(" "),
    _c("div", { staticClass: "card-body" }, [
      _c("h2", { staticClass: "text-center" }),
      _vm._v(" "),
      _c("form", { attrs: { method: "post", novalidate: "" } }, [
        _c("div", { staticClass: "form-group" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.name,
                expression: "name"
              }
            ],
            staticClass: "form-control",
            attrs: { type: "text", required: "" },
            domProps: { value: _vm.name },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.name = $event.target.value
              }
            }
          }),
          _vm._v(" "),
          _c("span", { staticClass: "floating-label" }, [
            !_vm.$v.name.$error
              ? _c("span", [_vm._v("Uživatelské jméno")])
              : _vm._e(),
            _vm._v(" "),
            _vm.$v.name.$error & !_vm.$v.name.required
              ? _c("span", { staticClass: "error" }, [
                  _vm._v("Uživatelské jméno je povinné.")
                ])
              : _vm._e()
          ])
        ]),
        _vm._v(" "),
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "form-group text-right" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-secondary",
              attrs: { type: "submit" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.submit()
                }
              }
            },
            [_vm._v("Přihlásit")]
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-group" }, [
      _c("input", {
        staticClass: "form-control",
        attrs: { type: "password", required: "" }
      }),
      _vm._v(" "),
      _c("span", { staticClass: "floating-label" }, [_vm._v("Heslo")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }