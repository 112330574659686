<template>
    <div>Ahoj</div>
</template>

<script>
export default {
    created() {
        
    }
}
</script>