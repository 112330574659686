var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "wrapper",
      class: [
        { "sidebar-collapsed": _vm.isSidebarCollapsed },
        _vm.isSidebarEntire ? "sidebar-entire" : "sidebar-compressed"
      ]
    },
    [
      _c("nav", { staticClass: "navbar navbar-expand navbar-light bg-light" }, [
        _c(
          "div",
          { staticClass: "sidebar-nav-row" },
          [
            _vm.$store.state.user.isLoggedIn
              ? _c(
                  "span",
                  {
                    staticClass: "sidebar-nav-icon sidebar-toggle",
                    on: {
                      click: function($event) {
                        return _vm.toggleSidebarVisibility()
                      }
                    }
                  },
                  [_vm._m(0)]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "router-link",
              { staticClass: "navbar-brand", attrs: { to: "/sign-in" } },
              [_vm._v("Flexee")]
            )
          ],
          1
        ),
        _vm._v(" "),
        _vm.$store.state.user.isLoggedIn
          ? _c("ul", { staticClass: "navbar-nav ml-auto" }, [
              _c("li", { staticClass: "nav-item dropdown" }, [
                _c(
                  "a",
                  {
                    staticClass: "nav-link dropdown-toggle",
                    attrs: {
                      href: "#",
                      role: "button",
                      "data-toggle": "dropdown",
                      "aria-haspopup": "true",
                      "aria-expanded": "false"
                    }
                  },
                  [_vm._v("My username")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "dropdown-menu dropdown-menu-right" },
                  [
                    _vm._m(1),
                    _vm._v(" "),
                    _vm._m(2),
                    _vm._v(" "),
                    _c("div", { staticClass: "dropdown-divider" }),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass: "dropdown-item",
                        attrs: { href: "#" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.logOut()
                          }
                        }
                      },
                      [_vm._v("Log out")]
                    )
                  ]
                )
              ])
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "content" }, [
        _vm.$store.state.user.isLoggedIn
          ? _c("aside", { staticClass: "sidebar" }, [
              _vm._m(3),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "sidebar-body vertical-scroll custom-scrollbar"
                },
                [
                  _c("nav", { staticClass: "sidebar-nav" }, [
                    _c(
                      "div",
                      {
                        staticClass: "sidebar-nav-group",
                        class: { active: _vm.subIsActive("/dashboard") }
                      },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "sidebar-nav-row sidebar-nav-item",
                            attrs: { to: "/dashboard" }
                          },
                          [
                            _c("span", { staticClass: "sidebar-nav-icon" }, [
                              _c("i", { staticClass: "icon-home" })
                            ]),
                            _vm._v(" "),
                            _c("span", { staticClass: "sidebar-nav-text" }, [
                              _vm._v("Dashboard")
                            ])
                          ]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "sidebar-nav-group",
                        class: { active: _vm.subIsActive("/parent") }
                      },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "sidebar-nav-row sidebar-nav-item",
                            attrs: { to: "/parent" }
                          },
                          [
                            _c("span", { staticClass: "sidebar-nav-icon" }, [
                              _c("i", { staticClass: "icon-chart-area" })
                            ]),
                            _vm._v(" "),
                            _c("span", { staticClass: "sidebar-nav-text" }, [
                              _vm._v("Parent sample")
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "router-link",
                          {
                            staticClass: "sidebar-nav-row sidebar-nav-subitem",
                            attrs: { to: "/parent/child" }
                          },
                          [
                            _c("span", { staticClass: "sidebar-nav-icon" }, [
                              _c("i", { staticClass: "icon-chart-bar" })
                            ]),
                            _vm._v(" "),
                            _c("span", { staticClass: "sidebar-nav-text" }, [
                              _vm._v("Child sample")
                            ])
                          ]
                        )
                      ],
                      1
                    )
                  ])
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "sidebar-footer" }, [
                _c("div", { staticClass: "sidebar-nav-row" }, [
                  _vm._m(4),
                  _vm._v(" "),
                  _c("span", { staticClass: "sidebar-nav-text" }, [
                    _vm._v("footer")
                  ]),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "sidebar-nav-icon ml-auto visible-entire",
                      on: {
                        click: function($event) {
                          return _vm.toggleSidebarWidth()
                        }
                      }
                    },
                    [_c("i", { staticClass: "icon-angle-double-left" })]
                  )
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "sidebar-nav-row visible-compressed" },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "sidebar-nav-icon",
                        on: {
                          click: function($event) {
                            return _vm.toggleSidebarWidth()
                          }
                        }
                      },
                      [_c("i", { staticClass: "icon-angle-double-right" })]
                    )
                  ]
                )
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "frame",
            class: {
              "justify-content-center": !_vm.$store.state.user.isLoggedIn
            }
          },
          [
            _c(
              "div",
              {
                staticClass: "container-fluid vertical-scroll",
                attrs: { id: "frame-scrollable" }
              },
              [_c("router-view")],
              1
            )
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "hamburger" }, [
      _c("span"),
      _vm._v(" "),
      _c("span"),
      _vm._v(" "),
      _c("span")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "dropdown-item", attrs: { href: "#" } }, [
      _c("i", [_vm._v("Action")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "dropdown-item", attrs: { href: "#" } }, [
      _c("i", [_vm._v("Another action")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "sidebar-header" }, [
      _c("div", { staticClass: "sidebar-nav-row" }, [
        _c("span", { staticClass: "sidebar-nav-icon" }, [
          _c("i", { staticClass: "icon-question-circle-o" })
        ]),
        _vm._v(" "),
        _c("span", { staticClass: "sidebar-nav-text" }, [_vm._v("header")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "sidebar-nav-icon" }, [
      _c("i", { staticClass: "icon-info" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }